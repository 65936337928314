<template>
  <CardToolbox>
    <SearchHeaderStyle>
      <sdPageHeader title="Bank">
        <template #subTitle>
          <a-input
            v-model:value="searchValue"
            @change="onHandleSearch"
            placeholder="Search by Name"
          >
            <template #suffix>
              <sdFeatherIcons type="search" size="16" />
            </template>
          </a-input>
        </template>
        <template #buttons>
          <sdButton
            @click="showModal"
            class="btn-add_new"
            bank="default"
            type="primary"
            key="1"
          >
            <a to="#">+ Add New</a>
          </sdButton>
        </template>
      </sdPageHeader>
    </SearchHeaderStyle>
  </CardToolbox>
  <Main>
    <a-row :gutter="15">
      <a-col class="w-100" :md="24">
        <sdCards title="Bank">
          <div v-if="isLoading" class="spin">
            <a-spin />
          </div>

          <div v-else>
            <TableWrapper class="table-data-view table-responsive">
              <a-table
                @change="onHandleTableChange"
                :pagination="pagination"
                :dataSource="dataSource"
                :columns="columns"
              />
            </TableWrapper>
          </div>
        </sdCards>
      </a-col>
      <sdModal
        title="Bank's information"
        :visible="localState.visible"
        :footer="null"
        :onCancel="handleCancel"
      >
        <div class="project-modal">
          <FormValidationWrap>
            <VerticalFormStyleWrap>
              <a-form
                name="sDash_validation-form"
                ref="formRef"
                @finish="onSubmit"
                :model="formState"
                :rules="rules"
                :layout="formState.layout"
              >
                <a-row :gutter="25">
                  <a-col :md="24" :xs="24">
                    <a-form-item ref="name" label="Account name" name="name">
                      <a-input
                        v-model:value="formState.name"
                        placeholder="Account name"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="24" :xs="24">
                    <a-form-item
                      ref="codeSwift"
                      label="SWIFT Code"
                      name="codeSwift"
                    >
                      <a-input
                        v-model:value="formState.codeSwift"
                        placeholder="SWIFT Code / BIC"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :xs="24" :md="24">
                    <a-form-item label="Address">
                      <a-textarea
                        v-model:value="formState.address"
                        :style="{ width: '100%' }"
                        placeholder="Address ..."
                        name="address"
                        class="sDash_unresizable"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :xs="24" :md="24">
                    <sdButton
                      htmlType="submit"
                      bank="full"
                      type="primary"
                      key="submit"
                    >
                      <span v-if="!formState.editionMode">Add new</span>
                      <span v-if="formState.editionMode">Edit bank</span>
                    </sdButton>
                  </a-col>
                </a-row>
              </a-form>
            </VerticalFormStyleWrap>
          </FormValidationWrap>
        </div>
      </sdModal>
    </a-row>
  </Main>
</template>
<script>
import {
  FormValidationWrap,
  VerticalFormStyleWrap,
} from "../forms/overview/Style";
import { Main, TableWrapper, CardToolbox, SearchHeaderStyle } from "../styled";
import { useStore } from "vuex";
import { computed, onMounted, reactive, ref, nextTick } from "vue";
import { onBeforeRouteLeave } from "vue-router";

const columns = [
  {
    title: "Bank",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "SWIFT Code / BIC",
    dataIndex: "codeSwift",
    key: "codeSwift",
  },
  {
    title: "Actions",
    dataIndex: "action",
    key: "action",
    width: "90px",
  },
];

const Bank = {
  name: "Bank",
  components: {
    Main,
    TableWrapper,
    FormValidationWrap,
    VerticalFormStyleWrap,
    CardToolbox,
    SearchHeaderStyle,
  },
  setup() {
    const { state, dispatch } = useStore();
    const BankApi = computed(() => state.BankApi.data.data);
    const isLoading = computed(() => state.BankApi.loading);
    const searchValue = ref("");
    var loading = false;
    var bankPagination = computed(() => state.BankApi.data);
    var sortDirections = "ascend";

    const localState = reactive({
      visible: false,
      editVisible: false,
      modalType: "primary",
      url: null,
    });

    const formRef = ref();
    const formState = reactive({
      layout: "vertical",
      id: 0,
      name: "",
      codeSwift: "",
      bic: "",
      address: "",
    });

    const rules = {
      name: [
        {
          required: true,
          message: "This field is required",
          trigger: "change",
        },
      ],
      codeSwift: [
        {
          required: true,
          message: "This field is required",
          trigger: "change",
        },
      ],
    };

    onMounted(() => {
      dispatch("bankGetPagedData", pagination.value);
      formState.editionMode = false;
    });
    const onHandleTableChange = (pagination) => {
      pagination = {
        ...pagination,
        sorter: {
          order: sortDirections,
        },
        current: pagination.current,
        OrderBy: sortDirections,
        searchValue: searchValue.value,
      };
      dispatch("bankGetPagedData", pagination);
    };
    var pagination = computed(() =>
      bankPagination.value && bankPagination.value.succeeded == true
        ? {
            current: bankPagination.value.pageNumber,
            total: searchValue.value
              ? bankPagination.value.recordsFiltered
              : bankPagination.value.recordsTotal,
            pageSize: bankPagination.value.pageSize,
            OrderBy: sortDirections,
            searchValue: "",
            sorter: {
              order: sortDirections,
            },
          }
        : {
            current: 1,
            pageSize: 10,
            OrderBy: sortDirections,
            searchValue: "",
            sorter: {
              order: sortDirections,
            },
          }
    );
    const handleDelete = (id) => {
      const confirm = window.confirm("Are you sure delete this?");
      if (confirm) {
        dispatch("bankApiDataDelete", { id });
      }
      return false;
    };

    const onHandleSearch = () => {
      sortDirections = "ascend";
      pagination = {
        ...pagination,
        current: 1,
        OrderBy: sortDirections,
        pageSize: 10,
        searchValue: searchValue.value,
      };

      dispatch("bankGetPagedData", pagination);
    };

    const dataSource = computed(() =>
      BankApi.value && BankApi.value.length
        ? BankApi.value.map((bank, key) => {
            const { id, name, codeSwift, bic, address } = bank;

            return {
              key: key + 1,
              name,
              codeSwift,
              bic,
              address,
              action: (
                <div class="table-actions">
                  <a-button class="edit" onClick={() => handleEdit(bank)}>
                    <sdFeatherIcons type="edit" bank={14} />
                  </a-button>
                  &nbsp;&nbsp;&nbsp;
                  <a-button class="delete" onClick={() => handleDelete(id)}>
                    <sdFeatherIcons type="trash-2" bank={14} />
                  </a-button>
                </div>
              ),
            };
          })
        : []
    );

    const onSubmit = () => {
      formRef.value
        .validate()
        .then(async () => {
          if (!formState.editionMode) {
            dispatch("bankSubmitData", {
              formState,
            });
          } else {
            dispatch("bankUpdateData", {
              formState,
            });
          }
          await nextTick();
          onCancel();
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    const showModal = () => {
      formState.editionMode = false;
      delete formState.id;
      formState.name = "";
      formState.codeSwift = "";
      formState.bic = "";
      formState.address = "";
      localState.visible = true;
    };
    const handleEdit = (bank) => {
      formState.editionMode = true;
      formState.id = bank.id;
      formState.name = bank.name;
      formState.codeSwift = bank.codeSwift;
      formState.bic = bank.bic;
      formState.address = bank.address;
      localState.visible = true;
    };

    const handleCancel = () => {
      onCancel();
    };

    const onCancel = () => {
      localState.visible = false;
      formState.editionMode = false;
      localState.update = {};
    };
    onBeforeRouteLeave(() => {
      dispatch("emptyBank");
    });
    return {
      BankApi,
      formRef,
      rules,
      onSubmit,
      isLoading,
      localState,
      handleDelete,
      dataSource,
      columns,
      onHandleSearch,
      loading,
      sortDirections,
      formState,
      searchValue,
      bankPagination,
      onHandleTableChange,
      pagination,
      showModal,
      handleCancel,
    };
  },
};

export default Bank;
</script>
<style lang="scss">
.sDash_form-action {
  display: flex;
  justify-content: space-between;
}
</style>
